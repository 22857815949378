<template>
  <v-alert width="100%" type="error" v-model="exibirError">
    <h6 class="alertaText">{{ mensagem }}</h6>
    <h6 class="alertaText">{{ errorCPF }}</h6>
    <h6 class="alertaText">{{ errorPassword }}</h6>
    <h6 class="alertaText">{{ errorCaptcha }}</h6>
  </v-alert>
</template>

<script>
export default {
  data() {
    return {
      exibirError: false,
      mensagem: "",
      errorCPF: "",
      errorPassword: "",
      errorCaptcha: "",
    };
  },

  props: {
    resource: {
      type: [Array, Object, String],
    },
  },

  watch: {
    resource: function () {
      this.errorLogin();
    },
  },

  methods: {
    errorLogin() {
      let errorLogin = this.resource;
      let errorCPF = this.resource.cpf;
      let errorPassword = this.resource.password;
      let errorCaptcha = this.resource.captcha;

      errorCPF = this.percorerObjetoError(errorCPF);
      errorPassword = this.percorerObjetoError(errorPassword);
      errorCaptcha = this.percorerObjetoError(errorCaptcha);

      if (errorCPF || errorPassword || errorCaptcha != null) {
        this.errorCPF = errorCPF;
        this.errorPassword = errorPassword;
        this.errorCaptcha = 'Preencha o captcha corretamente.';
      } else {
        this.mensagem = errorLogin;
      }

      this.exibirError = true;

      setTimeout(() => {
        this.exibirError = false;
      }, 3000);
    },

    percorerObjetoError(valor) {
      if (valor) {
        Object.values(valor).map((value) => {
          valor = value;
        });
      }
      return valor;
    },
  },
};
</script>

<style scoped>
.alertaText {
  font-size: 12px;
  font-weight: 500;
}
</style>
