<template>
  <div>
    <Login />
  </div>
</template>

<script>
import Login from '../components/login/Login-comp.vue';
export default {
  components: {
    Login
  }
}
</script>

